import Axios from "axios";
import token from "./setToken";

export default {

  async getSelectGroupList() {
    try {
      const res = await Axios.get("/api/MailSendGroupSelectGroupList", token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メール送信グループ一覧を取得できません[search]");
      throw error;
    }
  },

  async getDataList(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      const res = await Axios.post("/api/MailSendGroupList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メール送信グループ一覧を取得できません[search]");
      throw error;
    }
  },


  async getData(id) {
    try {
      const res = await Axios.get("/api/MailSendGroup/" + id, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メール送信グループを取得できません[getData]");
      throw error;
    }
  },


  async setData(data, mode) {
    let params = new URLSearchParams();
    params.append('data', JSON.stringify(data));
    params.append('mode', mode);

    try {
      const res = await Axios.post('/api/MailSendGroup', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("メール送信グループを設定できません");
      throw error;
    }
  },


  async delData(id) {
    try {
      const res = await Axios.delete('/api/MailSendGroup/' + id, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("メール送信グループを削除できません");
      throw error;
    }
  },

};
