<template>
  <v-container>
    <!-- 検索エリア -->
    <v-form
      ref="searchForm"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="3">
          <v-autocomplete label="委員会" 
                v-model="searchCondition.group_name1" 
                :items="groupName1List" 
                dense 
                :disabled="loading"
                :loading="loading"
                @change="search()"
                clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete label="部会" 
                v-model="searchCondition.group_name2" 
                :items="groupName2List" 
                dense 
                :disabled="loading"
                :loading="loading"
                @change="search()"
                clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="5">
            <v-text-field
              label="検索"
              v-model="searchCondition.searchString"
              dense
              :readonly="false"
              :clearable="false"
              :rules="[maxLength(50)]"
              :loading="loading"
            ></v-text-field>
        </v-col>
<!-- 
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-btn class="btn_font primary" width="120" v-on:click="search()" :disabled="loading" ><v-icon :small="true">mdi-magnify</v-icon>検索</v-btn>
        </v-col>
-->
      </v-row>
    </v-form>
    <!-- 会員一覧/選択エリア -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row class="mb-n7">
        <v-col v-if="!loading" cols="12" sm="12" md="12" lg="12">
          <input :disabled="filterItems.length <= 0" type='checkbox' id="select-all-checkbox" v-model="selectAll" /><label for="select-all-checkbox">すべて選択</label>
        </v-col>
        <v-col v-else cols="12" sm="12" md="12" lg="12">
          <input :disabled="true" type='checkbox' :value="false" /><label>すべて選択</label>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            ref="ref"
            :search="searchCondition.searchString"
            :headers="headers"
            :items="mailSendGroupList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border"
            height="calc(100vh - 500px)"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.sid">
                <td class="center">
                  <input type='checkbox' v-model="innerSelectedList" :value="item" />
                </td>
                <td class="left">
                  {{item.group_name1}}
                </td>
                <td class="left">
                  {{item.group_name2}}
                </td>
                <td class="left">
                  {{item.association_post}}
                </td>
                <td class="left">
                  {{item.name}}
                </td>
                <td class="left">
                  {{item.email}}
                </td>
                <td class="left">
                  {{item.company_name}}
                </td>
                <td class="left">
                  {{item.post}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- アクション -->
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="registData()" :disabled="loading" >確定</v-btn>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-form>

  </v-container>
</template>

<script>
import Mixin from "../mixins/const.js";
import Debug from '../Lib/Debug'
import Message from "../Lib/Message";

import MailSendGroup from "../model/mailSendGroup";

export default {
  mixins: [Mixin],

  components: {
  },

  props: {
    selectedList: Array,
    type: Number,
  },

  data: () => ({
    valid : true,
    loading: false,

    show: true,

    // 検索条件
    searchCondition: {
      group_name1: null,
      group_name2: null,
      searchString: "",
    },

    // メール送信グループ一覧
    mailSendGroupList: [],

    // テーブルヘッダー
    headers: [
      { text: '選択', align: 'center', sortable: true, value: 'check', width: "5%" },
      { text: '委員会', align: 'center', sortable: true, value: 'group_name1', width: "10%" },
      { text: '部会', align: 'center', sortable: true, value: 'group_name2', width: "10%" },
      { text: '協会役職', align: 'center', sortable: true, value: 'association_post', width: "10%" },
      { text: '氏名', align: 'center', sortable: true, value: 'name', width: "10%" },
      { text: 'メールアドレス', align: 'center', sortable: true, value: 'email', width: "20%" },
      { text: '会社名', align: 'center', sortable: true, value: 'company_name', width: "20%" },
      { text: '役職', align: 'center', sortable: true, value: 'post', width: "15%" },
    ],

    innerSelectedList: [],

    groupList: [],
  }),

  // ライフサイクルフック
  created: function () {
  },

  mounted: async function () {
    await this.getSelectGroupList();
    this.search();
  },

  methods: {
    /** グループ一覧 */
    async getSelectGroupList() {
      Debug.log('function[loadList]');
      this.loading = true;

      try {
        const res = await MailSendGroup.getSelectGroupList();
        Debug.log(res.data);
        //if(res.data.length > 0) {
          this.groupList = res.data;
        //}
      } catch (error) {
        Message.err(error, "メール送信グループ一覧を取得できませんでした");
      }
      Debug.log(this.groupList);

      this.loading = false;
    },

    /** 表示更新 */
    async loadList() {
      Debug.log('function[loadList]');
      this.loading = true;

      try {
        const res = await MailSendGroup.getDataList(this.searchCondition);
        Debug.log(res.data);
        //if(res.data.length > 0) {
          this.mailSendGroupList = res.data;
        //}
      } catch (error) {
        Message.err(error, "メール送信グループ一覧を取得できませんでした");
      }
      Debug.log(this.mailSendGroupList);

      this.loading = false;
    },

    search() {
      Debug.log("function[search]");
      this.loadList();
    },

    /** メール送信グループ設定 */
    registData() {
      if (!confirm("確定します。よろしいですか？")) return;
      this.$emit('registEvt', this.type, this.innerSelectedList);
      this.close();
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.close();
    },

    close() {
      this.$emit("closeEvt");
    },
  },

  computed: {

    filterItems() {
      if (this.mailSendGroupList.length > 0) {
        return this.$refs.ref.$children[0].filteredItems;
      } else {
        return [];
      }
    },

    groupName1List() {
      if (this.groupList.length > 0) {
        return this.groupList.map(item => item["group_name1"]);
      } else {
        return [];
      }
    },
    groupName2List() {
      const tmpList = this.groupList.filter(item => item.group_name1 == this.searchCondition.group_name1);
      if (tmpList.length > 0) {
        return tmpList.map(item => item["group_name2"]);
      } else {
        return [];
      }
    },

    // 選択リストにフィルターアイテムが含まれている
    isIncludesSelectedList() {
      let cnt = 0;
      this.filterItems.forEach(function (item) {
        if(this.innerSelectedList.some(select => select.sid === item.sid)){
          cnt++;
        }
      }.bind(this));

      Debug.log2('this.innerSelectedList:', this.innerSelectedList);
      Debug.log2('this.filterItems:', this.filterItems);
      Debug.log2('isIncludesSelectedList:', cnt);

      return (cnt > 0) && (this.filterItems.length === cnt);
    },

    selectAll: {
      get: function () {
        Debug.log('selectAll get');
        return this.filterItems ? ((this.filterItems.length > 0) && (this.isIncludesSelectedList)) : false;
      },
      set: function (sid) {
        let tmpSelectedList = [];
        this.filterItems.forEach(function (mailSendGroupList) {
          tmpSelectedList.push(mailSendGroupList);
        });
        if (sid) {
          // すべて選択の場合
          Debug.log('selectAll set');
          // this.filterItems.forEach(function (memberList) {
          //     //if(memberList.survStatus == 0) {
          //       tmpSelectedList.push(memberList);
          //     //}
          //   });
          let tmpInnerSelectedList = Array.from(new Set(this.innerSelectedList.concat(tmpSelectedList)));
          this.innerSelectedList = tmpInnerSelectedList.filter((element, index, self) => 
                                      self.findIndex(e => 
                                        e.sid === element.sid
                                      ) === index
                                    );
        } else {
          // すべて選択解除の場合
          Debug.log('selectAll false');
          Debug.log2('this.innerSelectedList',this.innerSelectedList);
          Debug.log2('tmpSelectedList',tmpSelectedList);
          let tmpInnerSelectedList = this.innerSelectedList;
          // 表示されているすべてのアイテムを削除
          tmpSelectedList.forEach(function (d) {
            tmpInnerSelectedList = tmpInnerSelectedList.filter(function(b){
                                        return b.sid !== d.sid
                                      });
            
          });

          this.innerSelectedList = tmpInnerSelectedList;
        }
      }
    },
  },

  watch: {
    selectedList: {
      immediate: true,
      handler(value) {
        this.innerSelectedList = value;
      },
    },
  },
};
</script>

<style scoped>
.v-input--radio-group {
  margin: 0;
}

.disablecolor {
  background: #bbbbbb;
}

</style>
