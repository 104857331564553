import Axios from "axios";
import token from "./setToken";

export default {
  async send(mailData, mailDestArray, sendData, attachment, sendGroup) {
  
    let params = new FormData();
    params.append('mailData', JSON.stringify(mailData));
    params.append('mailDestArray', JSON.stringify(mailDestArray));
    params.append('sendData', JSON.stringify(sendData));
    params.append('sendGroup', JSON.stringify(sendGroup));
    // ファイル
    for(let i=0; i < attachment.length; i++) {
      params.append('attachment['+i+']', attachment[i]);
    }

    try {
      const res = await Axios.post('/api/MailSend', params, token.setTokenToHeaderFile());
      return res;

    } catch (error) {
      console.error("メールを送信できません");
      throw error;
    }
  },
};