<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-2">
        <v-col>
          <h2>お知らせメール送信</h2>
        </v-col>
      </v-row>

      <!-- メール編集 -->
      <v-row class="">
        <v-col cols="2" sm="2" md="2" lg="2">
          <h3>メール編集</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="発信者"
            v-model="mailData.mailFrom"
            dense
            :readonly="true"
            :rules="[required]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(0, true, mailDestArray.tmpFromList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>発信者変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="宛先"
            v-model="mailData.mailTo"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(1, false, mailDestArray.tmpToList)" :disabled="loading" >
            <v-icon :small="true">mdi-border-color</v-icon>
            宛先変更
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="CC"
            v-model="mailData.mailCC"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(2, false, mailDestArray.tmpCCList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>CC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="BCC"
            v-model="mailData.mailBCC"
            dense
            :readonly="true"
            :clearable="false"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(3, false, mailDestArray.tmpBCCList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>BCC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-text-field
            label="件名"
            v-model="mailData.subject"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[required]"
            :maxLength="100"
            counter="100"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="本文"
            v-model="mailData.body"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[required]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="署名"
            v-model="mailData.signature"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-for="n in (attachment.length + 1)" :key="n">
        <v-col cols="12" sm="12" md="12" lg="12">
          <!-- <div
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragover.prevent
            @drop.prevent="dropFile"
          > -->
          <div>
            <v-file-input
              :background-color="isEnter ? 'info' : 'transparent'"
              label="添付"
              v-model="attachment[n-1]"
              dense
              :readonly="false"
              :clearable="true"
              :rules="[]"
              :loading="loading"
              truncate-length="50"
              placeholder="ファイルを選択します。"
              persistent-placeholder
              @change="filterTmpAttachment"
            ></v-file-input>
          </div>
        </v-col>
      </v-row>

      <!-- 送信先会員 -->
      <v-row class="mt-5">
        <v-col>
          <h3>送信先会員</h3>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="show(sendData.dest)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>送信先会員変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="sendData.dest"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border send-table"
            height="auto"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.memberNo">
                <td class="center">
                  {{item.memberNo}}
                </td>
                <td class="left">
                  {{item.companyName}}
                </td>
                <td class="left">
                  {{item.officeName}}
                </td>
                <td class="left">
                  {{item.toList}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <NumberTextField
            label="送信先会員・協会件数"
            :value="sendTargetCnt"
            dense
            :readonly="true"
            :rules="[]"
            suffix="件"
          ></NumberTextField>
        </v-col>
      </v-row>

      <!-- メール送信グループTo -->
      <v-row class="mt-5">
        <v-col>
          <h3>メール送信グループ宛先</h3>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="250" v-on:click="showSendGroup(1, sendGroup.sendGroupEmailTo)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>メール送信グループ宛先変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headersSendGroup"
            :items="sendGroup.sendGroupEmailTo"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border send-table"
            height="auto"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.sid">
                <td class="left">
                  {{item.group_name1}}
                </td>
                <td class="left">
                  {{item.group_name2}}
                </td>
                <td class="left">
                  {{item.association_post}}
                </td>
                <td class="left">
                  {{item.name}}
                </td>
                <td class="left">
                  {{item.email}}
                </td>
                <td class="left">
                  {{item.company_name}}
                </td>
                <td class="left">
                  {{item.post}}
                </td>
                <td class="left">
                  {{to_address(item)}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- メール送信グループCC -->
      <v-row class="mt-5">
        <v-col>
          <h3>メール送信グループCC</h3>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="250" v-on:click="showSendGroup(2, sendGroup.sendGroupEmailCC)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>メール送信グループCC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headersSendGroup"
            :items="sendGroup.sendGroupEmailCC"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border send-table"
            height="auto"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.sid">
                <td class="left">
                  {{item.group_name1}}
                </td>
                <td class="left">
                  {{item.group_name2}}
                </td>
                <td class="left">
                  {{item.association_post}}
                </td>
                <td class="left">
                  {{item.name}}
                </td>
                <td class="left">
                  {{item.email}}
                </td>
                <td class="left">
                  {{item.company_name}}
                </td>
                <td class="left">
                  {{item.post}}
                </td>
                <td class="left">
                  {{to_address(item)}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- メール送信グループBCC -->
      <v-row class="mt-5">
        <v-col>
          <h3>メール送信グループBCC</h3>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="250" v-on:click="showSendGroup(3, sendGroup.sendGroupEmailBCC)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>メール送信グループBCC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headersSendGroup"
            :items="sendGroup.sendGroupEmailBCC"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border send-table"
            height="auto"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.sid">
                <td class="left">
                  {{item.group_name1}}
                </td>
                <td class="left">
                  {{item.group_name2}}
                </td>
                <td class="left">
                  {{item.association_post}}
                </td>
                <td class="left">
                  {{item.name}}
                </td>
                <td class="left">
                  {{item.email}}
                </td>
                <td class="left">
                  {{item.company_name}}
                </td>
                <td class="left">
                  {{item.post}}
                </td>
                <td class="left">
                  {{to_address(item)}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- アクション -->
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="registData()" :disabled="loading" ><v-icon :small="true">mdi-send</v-icon>メール送信</v-btn>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="cancel()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>

    </v-container>

    <!-- modal -->
    <modal name="modal-dialog-from" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal-from">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>メールアドレス選択</h2>
          <v-icon x-large v-on:click="hideFrom()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectEmailAddress v-on:closeEvt="hideFrom" v-on:registEvt="updateEmailAddressList" :selectedList="modalSelectedList" :type="emailType" :solo="emailSolo" />
        </div>
      </div>
    </modal>

    <!-- modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>会員選択</h2>
          <v-icon x-large v-on:click="hide()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectMember v-on:closeEvt="hide" v-on:registEvt="updateDest" :selectedList="modalSelectedList" :searchType="''" :targetDate="''"/>
        </div>
      </div>
    </modal>

    <!-- modal -->
    <modal name="modal-dialog-group" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>メール送信グループ選択</h2>
          <v-icon x-large v-on:click="hideSendGroup()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectSendMailGroup v-on:closeEvt="hideSendGroup" v-on:registEvt="updateSendGroup" :selectedList="modalSelectedList" :type="sendGroupType"/>
        </div>
      </div>
    </modal>

  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import Message from "../Lib/Message";

import SelectMember from './SelectMember.vue';
import SelectEmailAddress from './SelectEmailAddress.vue';
import SelectSendMailGroup from './SelectSendMailGroup.vue';
import NumberTextField from "./common/Number_text_field.vue";

import MailTemplate from "../model/mailTemplate";
import MailSend from "../model/mailSend";

export default {
  components: {
    SelectMember,
    SelectEmailAddress,
    SelectSendMailGroup,
    NumberTextField,
  },

  data: () => ({
    valid : true,
    loading: false,

    // ファイル ドラッグアンドドロップ
    isEnter: false,

    // メールアドレス選択
    emailType: 0,
    emailSolo: false,

    // テンプレートデータ
    mailData: {},
    templateData: {
      templateId: null,
      type: 0,
      mailFrom: '',
      mailTo: '',
      mailCC: '',
      mailBCC: '',
      subject: '',
      body: '',
      signature: '',
    },
    // メールアドレス一時リスト
    mailDestArray: {
      tmpFromList: [],
      tmpToList: [],
      tmpCCList: [],
      tmpBCCList: [],
    },

    // 添付一時ファイル(ドラッグアンドドロップ用)
    tmpDropFiles: [],

    // テーブルヘッダー
    headers: [
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "10%" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "25%" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "25%" },
        { text: '宛先', align: 'center', sortable: true, value: 'toList', width: "40%" },
    ],

    // テーブルヘッダー
    headersSendGroup: [
        { text: '委員会', align: 'center', sortable: true, value: 'memberNo', width: "10%" },
        { text: '部会', align: 'center', sortable: true, value: 'companyName', width: "10%" },
        { text: '協会役職', align: 'center', sortable: true, value: 'officeName', width: "10%" },
        { text: '氏名', align: 'center', sortable: true, value: 'toList', width: "10%" },
        { text: 'メールアドレス', align: 'center', sortable: true, value: 'toList', width: "15%" },
        { text: '会社名', align: 'center', sortable: true, value: 'toList', width: "15%" },
        { text: '役職', align: 'center', sortable: true, value: 'toList', width: "15%" },
        { text: '宛先', align: 'center', sortable: true, value: 'toList', width: "15%" },
    ],

    // メールデータ
    sendData: {
      // 送信対象(会員番号)リスト
      dest: [],
      formatType: 1,    // お知らせ
    },

    // 送信先グループemail
    sendGroupType: 1,
    sendGroup: {
      sendGroupEmailTo: [],
      sendGroupEmailCC: [],
      sendGroupEmailBCC: [],
    },

    // 添付ファイル
    attachment: [],

    // モーダル用リスト
    modalList: [],
    modalSelectedList: [],

  }),

  // ライフサイクルフック
  created: function () {
    this.init();

    this.getData();
  },

  mounted: async function () {

  },

  methods: {
    init() {
      Debug.log('function[init]');
      this.loading = true;

      this.mailData = this.$options.data().mailData;
      this.templateData = this.$options.data().mailData;
      // メールアドレス一時リスト
      this.mailDestArray = this.$options.data().mailDestArray;

      // 添付一時ファイル(ドラッグアンドドロップ用)
      this.tmpDropFiles = this.$options.data().tmpDropFiles;

      // メールデータ
      this.sendData = this.$options.data().sendData;

      // 添付ファイル
      this.attachment = this.$options.data().attachment;

      // モーダル用リスト
      this.modalList = this.$options.data().modalList;
      this.modalSelectedList = this.$options.data().modalSelectedList;

      this.loading = false;
    },

     /** 表示更新 */
     async getData() {
      Debug.log('function[getData]');
      this.loading = true;

      try {
        // お知らせのメールテンプレート取得
        const res = await MailTemplate.getMailTemplateDataForType(8);
        Debug.log(res.data);
        // レスポンス確認
        if(res.data.template.length > 0 && res.status == 200) {
          this.templateData = res.data.template[0];
          this.mailDestArray.tmpFromList = res.data.from;
          this.mailDestArray.tmpToList = res.data.to;
          this.mailDestArray.tmpCCList = res.data.cc;
          this.mailDestArray.tmpBCCList = res.data.bcc;
        }
        this.mailData = JSON.parse(JSON.stringify(this.templateData));
      } catch (error) {
        Message.err(error, "メールテンプレートを取得できませんでした");
      }
      this.loading = false;
    },

   // ファイル ドラッグアンドドロップ
   dragEnter() {
      Debug.log('function[dragEnter]');
      this.isEnter = false;
    },
    dragLeave() {
      Debug.log('function[dragLeave]');
      this.isEnter = false;
    },
    dropFile() {
      Debug.log('function[dropFile]');
      // ドロップされたファイルの取得
      this.tmpDropFiles = [...event.dataTransfer.files];
      Debug.log(this.tmpDropFiles);

      // 添付ファイルに追加
      for(let i=0; i < this.tmpDropFiles.length; i++) {
        this.attachment.push(this.tmpDropFiles[i]);
      }
      // 一時ファイルの初期化
      this.tmpDropFiles = [];

      this.isEnter = false;
    },
    filterTmpAttachment() {
      // リストからnullを削除
      Debug.log('function[filterTmpAttachment]');
      this.attachment = this.attachment.filter(v => !!v);
    },

    // メールアドレス更新
    updateEmailAddressList (type, innerSelectedList, tmpStr) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      switch(type) {
        case 0:
          // 発信者
          //this.mailData.mailFrom = (tmpStr === '') ? ((this.templateData.mailFrom == null || this.templateData.mailFrom == '') ? '' : this.templateData.mailFrom) : tmpStr;
          this.mailData.mailFrom = tmpStr;
          this.mailDestArray.tmpFromList = [innerSelectedList];
          Debug.log(this.mailDestArray.tmpFromList);
          break;

        case 1:
          // 宛先
          //this.mailData.mailTo = (tmpStr === '') ? this.templateData.mailTo : ((this.templateData.mailTo == null || this.templateData.mailTo == '') ? tmpStr : this.templateData.mailTo + ',' + tmpStr);
          this.mailData.mailTo = tmpStr;
          this.mailDestArray.tmpToList = innerSelectedList;
          break;

        case 2:
          // CC
          //this.mailData.mailCC = (tmpStr === '') ? this.templateData.mailCC : ((this.templateData.mailCC == null || this.templateData.mailCC == '') ? tmpStr : this.templateData.mailCC + ',' + tmpStr);
          this.mailData.mailCC = tmpStr;
          this.mailDestArray.tmpCCList = innerSelectedList;
          break;

        case 3:
          // BCC
          //this.mailData.mailBCC = (tmpStr === '') ? this.templateData.mailBCC : ((this.templateData.mailBCC == null || this.templateData.mailBCC == '') ? tmpStr : this.templateData.mailBCC + ',' + tmpStr);
          this.mailData.mailBCC = tmpStr;
          this.mailDestArray.tmpBCCList = innerSelectedList;
          break;
      }

      this.loading = false;
    },

    // 送信対象リスト更新
    updateDest (innerSelectedList) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      this.sendData.dest = innerSelectedList;
      Debug.log(this.sendData.dest);

      this.loading = false;
    },

    // メール送信グループリスト更新
    updateSendGroup (type, innerSelectedList) {
      Debug.log('function[updateSendGroup]');
      this.loading = true;

      // 編集画面での変更を反映する
      switch(type) {
        case 0:
          // 発信者
          break;

        case 1:
          // 宛先
          this.sendGroup.sendGroupEmailTo = innerSelectedList;
          Debug.log(this.sendGroup.sendGroupEmailTo);
          break;

        case 2:
          // CC
          this.sendGroup.sendGroupEmailCC = innerSelectedList;
          Debug.log(this.sendGroup.sendGroupEmailCC);
          break;

        case 3:
          // BCC
          this.sendGroup.sendGroupEmailBCC = innerSelectedList;
          Debug.log(this.sendGroup.sendGroupEmailBCC);
          break;
      }

      this.loading = false;
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      //this.$router.back();
      this.init();
      this.getData();
    },

    // メール送信
    async registData() {
      Debug.log2('this.mailDestArray', this.mailDestArray);
      Debug.log2('this.sendData', this.sendData);
      Debug.log2('sendTargetCnt', this.sendTargetCnt);
      
      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("メールを送信します。よろしいですか？")) return;
      this.loading = true;
      try {
        let res = null;

        res = await MailSend.send(this.mailData, this.mailDestArray, this.sendData, this.attachment, this.sendGroup);

        // サーバーサイドのバリデーション 
        if(res.status == 200) {
          const validationMsg = res.data;
          if (validationMsg) {
            let message = "";
            if (Array.isArray(validationMsg)) {
              validationMsg.forEach(m => message += m + '\n')
            } else {
              message = validationMsg;
            }
            alert(message);

            this.loading = false;
            return;
          } else {
            // 送信成功
            alert("送信しました。");
            //this.$router.back();
            this.$router.push("/mail_send_history_list");
          }
        }

      } catch (error) {
        Message.err(error, "送信できませんでした");
      }

      this.loading = false;
    },

    /** アドレス帳モーダル表示用 */
    showEmailAddress (type, solo, selectedList) {
      Debug.log('function[show]');

      this.emailType = type;
      this.emailSolo = solo;
      this.modalSelectedList = selectedList;
      this.$modal.show('modal-dialog-from');
    },
    hideFrom : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog-from');
    },
    /** モーダル表示用 */
    show (selectedList) {
      Debug.log('function[show]');

      this.modalSelectedList = selectedList;
      this.$modal.show('modal-dialog');
    },
    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');
    },
    /** メール送信グループモーダル表示用 */
    showSendGroup (type, selectedList) {
      Debug.log('function[showSendGroup]');

      this.sendGroupType = type;
      this.modalSelectedList = selectedList;
      this.$modal.show('modal-dialog-group');
    },
    hideSendGroup : function () {
      Debug.log('function[hideSendGroup]');
      this.$modal.hide('modal-dialog-group');
    },

    to_address(item) {
      if (!item.email) {
        return "";
      }
      return item.to + '<' + item.email + '>';
    },
  },

  computed: {
    // 送信対象会員数
    sendTargetCnt() {
      let toCnt = this.mailDestArray.tmpToList.map((item) => {
        return item.memberNo;
      });
      let ccCnt = this.mailDestArray.tmpCCList.map((item) => {
        return item.memberNo;
      });
      let bccCnt = this.mailDestArray.tmpBCCList.map((item) => {
        return item.memberNo;
      });
      let destCnt = this.sendData.dest.map((item) => {
        return item.memberNo;
      });

      let cnt = Array.from(new Set(toCnt.concat(ccCnt, bccCnt, destCnt)));

      return cnt.length;
    },
  },

  watch: {

  },
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

.send-table >>> .v-data-table__wrapper {
  max-height: 500px;
}

</style>
